import React, { Component } from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import mapStyles from "./map-styles";

const Container = Styled.div`
    background-color: ${(props) => props.theme.colors.gray.mediumer}; 
    border-radius: 2rem;
`;

const ContainerTxt = Styled.div`
  margin-top: 3rem;
  padding: 5rem;
`;

const ContainerMap = Styled.div`
  border-radius: 0 0 2rem 2rem;
  position: relative;
  height: 25rem;
  background: #333;
  overflow: hidden;

  ${Media.greaterThan("medium")`
    max-width: 100%;
  `}
`;

const Headline = Styled.p`
    font-size: 3rem;
    font-weight: 600;
    margin: 0;
`;

const ListItem = Styled.li`
    font-size: 1.8rem;
    list-style-type: none;
`;

const ListItemTitle = Styled.p`
    font-weight: bold;
    margin: 1rem 0 0 0;
`;

const Map = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const SmallHeadline = Styled.p`
    font-size: 1.9rem;
    line-height: 2rem;
    margin-bottom: 2rem;
`;

export default class extends Component {
  componentDidMount() {
    // eslint-disable-line
    if (typeof window === "undefined") return;

    const map = new window.google.maps.Map(
      document.getElementById(this.props.id),
      {
        zoom: this.props.zoom,
        center: new window.google.maps.LatLng(this.props.lat, this.props.lng),
        styles: mapStyles,
        disableDefaultUI: true,
      }
    );

    const marker = new window.google.maps.Marker({
      // eslint-disable-line
      position: new window.google.maps.LatLng(this.props.lat, this.props.lng),
      map,
      icon: "/assets/maps-marker-icon.png",
    });
  }

  render() {
    // eslint-disable-line
    return (
      <Container>
        <ContainerTxt>
          <SmallHeadline>
            We have been globally distributed from day one but still have a
            small office:
          </SmallHeadline>

          <ListItem>
            <Headline>{this.props.title}</Headline>
            {this.props.address}
            <br />
            {this.props.postcode}
            <br />
            {this.props.country}
          </ListItem>

          {this.props.vat && (
            <ListItem>
              <ListItemTitle>VAT</ListItemTitle>
              {this.props.vat}
            </ListItem>
          )}
        </ContainerTxt>

        <ContainerMap>
          <Map id={this.props.id} />
        </ContainerMap>
      </Container>
    );
  }
}
