import Styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";

const Textarea = Styled(TextareaAutosize)`
  display: block;
  width: 100%;
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1.8rem;
  line-height: 1.5;
  padding: 1rem 0;
  font-weight: normal;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.black};
  outline: none;
  transition: border .3s;
  resize: none;
  
    ${(props) =>
      props.backgrounded &&
      `
    background-color: ${props.theme.colors.gray.mediumer};
    border-bottom: 0;
    border-radius: 1rem;
    padding: 1rem 1.7rem;
    border-left: 0;
    border-right: 0;
    border-top: 0;
  `}

  &::placeholder {
    color: ${(props) => props.theme.colors.black};
  }

  &::selection {
    background-color: ${(props) => props.theme.colors.primary};
  }
  
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.black};
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}
`;

export default Textarea;
