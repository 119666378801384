import * as React from "react";
import { Component } from "react";
import { graphql } from "react-apollo";
import { flowRight as compose } from "lodash";
import gql from "graphql-tag";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";

import { getValidationErrors } from "../../../../helpers/validation";

import Form from "../../../form/Form";
import Label from "../../../form/Label";
import FormGroup from "../../../form/FormGroup";
import Button from "../../../buttons/Button";
import FormErrors from "../../../form/FormErrors";
import Input from "../../../form/Input";
import Textarea from "../../../form/Textarea";
import Select from "../../../form/Select";

const OPTIONS = [
  { value: "", label: "Select subject", disabled: true },
  { value: "Press", label: "Press Inquiry" },
  { value: "Investor", label: "Investor" },
  { value: "Academic Research", label: "Academic Research" },
  { value: "Career", label: "Career" },
  { value: "Privacy Policy", label: "Privacy Policy" },
  { value: "Other", label: "Other" },
];

const Container = Styled.div`

  ${Button} {
    margin-top: 2.5rem;
    font-size: 1.6rem;
    border-radius: 1rem;
  }
`;

const Headline = Styled.h3`
  font-size: 3.0rem;
  font-weight: 700;
  color: inherit;
  line-height: 1.0675;
  margin: 0 0 3rem 0;
  
  ${Media.lessThan("660px")`
    margin: 5rem 0 3rem 0;
  `}
`;

const Description = Styled.p`
  font-size: 1.6rem;
  font-weight: 400;
  color: inherit;
  line-height: 1.375;
  margin: 0 0 5rem 0;

  ${Media.greaterThan("medium")`
    font-size: 2rem;
  `}
`;

const Success = Styled.div``;

class ContactForm extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    loading: false,
    success: false,
    inputs: {
      name: "",
      company: "",
      subject: "",
      email: "",
      message: "",
    },
    errors: {
      subject: [],
      email: [],
      message: [],
    },
  };

  handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const { contactRequest } = this.props;
      const { inputs } = this.state;

      this.setState({
        loading: true,
        errors: {
          subject: [],
          email: [],
          message: [],
        },
      });

      await contactRequest({
        name: inputs.name,
        company: inputs.company,
        subject: inputs.subject,
        email: inputs.email,
        message: inputs.message,
      });

      this.setState({
        loading: false,
        success: true,
      });
    } catch (error) {
      this.setState({
        loading: false,
        errors: {
          ...this.state.errors,
          ...getValidationErrors(error),
        },
      });
    }
  };

  handleInputChange = (e) => {
    this.setState({
      inputs: {
        ...this.state.inputs,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    const { loading, success, inputs, errors } = this.state;

    return (
      <Container>
        {!success && (
          <Form onSubmit={this.handleSubmit}>
            <Headline>Drop a line</Headline>

            <FormGroup>
              <Label>Name</Label>
              <Input
                type="text"
                name="name"
                value={inputs.name}
                onChange={this.handleInputChange}
                placeholder="Full name"
                backgrounded
              />
            </FormGroup>

            <FormGroup>
              <Label>Email</Label>
              <Input
                type="text"
                name="email"
                value={inputs.email}
                onChange={this.handleInputChange}
                placeholder="Email address"
                backgrounded
              />

              <FormErrors errors={errors.email} />
            </FormGroup>

            <FormGroup>
              <Label>Company</Label>
              <Input
                type="text"
                name="company"
                value={inputs.company}
                onChange={this.handleInputChange}
                placeholder="Company / Institution"
                backgrounded
              />
            </FormGroup>

            <FormGroup>
              <Label>Subject</Label>

              <Select
                name="subject"
                value={inputs.subject}
                placeholder="Select subject"
                onChange={this.handleInputChange}
                options={OPTIONS}
              />

              <FormErrors errors={errors.subject} />
            </FormGroup>

            <FormGroup>
              <Label>Message</Label>

              <Textarea
                name="message"
                value={inputs.message}
                onChange={this.handleInputChange}
                placeholder="Message"
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="off"
                spellCheck="false"
                backgrounded
              />

              <FormErrors errors={errors.message} />
            </FormGroup>

            <FormGroup>
              <Button
                variant="primarylight"
                solid
                type="submit"
                expand
                loading={loading}
              >
                Send message
              </Button>

              <FormErrors errors={errors.message} />
            </FormGroup>
          </Form>
        )}

        {success && (
          <Success>
            <Headline>Thank you</Headline>
            <Description>We have received your message.</Description>
          </Success>
        )}
      </Container>
    );
  }
}

const contactRequest = graphql(
  gql`
    mutation ContactRequest(
      $name: String!
      $company: String!
      $email: String!
      $subject: String!
      $message: String!
    ) {
      contactRequest(
        name: $name
        company: $company
        email: $email
        subject: $subject
        message: $message
      ) {
        mutationId
      }
    }
  `,
  {
    props({ mutate }) {
      return {
        contactRequest(variables) {
          return mutate({ variables });
        },
      };
    },
  }
);

export default compose(contactRequest)(ContactForm);
