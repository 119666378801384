import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Layout from "../../components/layout/Layout";
import Content from "../../components/layout/Content/Content";
import ContactHeader from "../../components/pages/contact/ContactHeader";
import ContactForm from "../../components/pages/contact/ContactForm";
import ContactInfos from "../../components/pages/contact/ContactInfos";
import ContactMap from "../../components/pages/contact/ContactMap";
import Shoutout from "../../components/pages/signup/Shoutout";
import FormGroup from "../../components/form/FormGroup";

const Container = Styled.div`
  margin: 10rem 0 10rem 0;

  ${Media.greaterThan("medium")`
    margin: 16rem 0 10rem 0;
  `}
  
  ${Media.lessThan("small")`
    margin: 10rem 0 5rem 0;
  `}
  
  ${FormGroup} {
    margin: 0 0 1.5rem 0;
  }
`;

const Columns = Styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 10rem;
  
  ${Media.lessThan("660px")`
    flex-direction: column-reverse;
    align-items: center;
    & > * {width: 100%}
  `}
  
  ${Media.lessThan("small")`
    margin-bottom: 3.5rem;
  `}
`;

const Column = Styled.div`
  position: relative;
  
  ${Media.lessThan("small")`
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.lessThan("medium")`
    width: 50%;
    
    &:first-of-type {
      margin-right: 3rem;
    }
    
    &:last-of-type {
      width: 32rem;
    }
    `}
   
  ${Media.lessThan("660px")`
    width: 100%;
    margin: 0 auto;
    
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
    min-width: 0;
    padding: 0 3rem;
  `}

  ${Media.greaterThan("large")`
    padding: 0 6rem;
  `}
`;

const SecurityContact = Styled.p`
  font-size: 1.9rem;
  line-height: 2rem;
  margin-bottom: 2rem;
`;

const MapCoordCPH = { lat: 55.6771275, lng: 12.5694678 };

const Contact = ({ data }) => (
  <Layout>
    <Meta
      title="Contact Uizard | Reach Out To The Uizard Team | Uizard"
      description="We have been globally distributed from day one but still have a small office. Explore the future with us. Feel free to get in touch online today."
      url="/contact/"
    />

    <Content>
      <Container>
        <ContactHeader
          headline="Contact"
          tagline="Explore the future with us. Feel free to get in touch."
        />
        <Columns>
          <Column>
            <ContactForm />
            <SecurityContact>
              For ethical vulnerability disclosure, please contact{" "}
              <b>security _at_ uizard _dot_ io</b>
            </SecurityContact>
          </Column>

          <Column>
            <ContactMap
              id="map_cph"
              title="Uizard"
              address="Vestergade 29"
              postcode="1456, Copenhagen"
              country="Denmark"
              vat="DK40868445"
              lat={MapCoordCPH.lat}
              lng={MapCoordCPH.lng}
              zoom={14}
            />
          </Column>
        </Columns>

        <Shoutout
          headline="Design effortlessly"
          description="Probably the easiest design tool you'll ever use"
          cta="Sign up for free"
          imageA={data.shoutoutImageA.childImageSharp}
        />
      </Container>
    </Content>
  </Layout>
);

export default Contact;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
