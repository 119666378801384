import * as React from "react";
import Styled from "styled-components/macro";
// @ts-ignore
import get from "lodash/get";

const Container = Styled.div`
  position: relative;

  ${(props) =>
    props.disabled &&
    `
    cursor: not-allowed;
  `}
`;

const Select = Styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

const Option = Styled.option`
  color: ${(props) => props.theme.colors.black};

  &:first-child {
    color: ${(props) => props.theme.colors.gray.light};
  }
`;

const Label = Styled.div`
  font-family: ${(props) => props.theme.fonts.regular};
  font-size: 1.8rem;
  height: 5rem;
  line-height: 5rem;
  background-color: ${(props) => props.theme.colors.gray.mediumer};
  padding: 0 1.7rem;
  color: ${(props) => props.theme.colors.piner};
  border-radius: 1rem;

  ${(props) =>
    props.placeholder &&
    `
    color: ${props.theme.colors.piner};
  `}
`;

const Selector = (props) => {
  const { options, name, value, placeholder, disabled, onChange } = props;

  const current = options.find((o) => o.value === value);
  const label = get(current, "label") || placeholder || "Select";

  return (
    <Container disabled={disabled}>
      <Select name={name} value={value} disabled={disabled} onChange={onChange}>
        {options.map((option) => (
          <Option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </Option>
        ))}
      </Select>

      <Label placeholder={value ? 0 : 1}>{label}</Label>
    </Container>
  );
};

export default Selector;
